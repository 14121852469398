import * as BABYLON from 'babylonjs';
import LineUtils from './LineUtils';
import BasicUtils from './BasicUtils';

export type MarkSettings = {
  name?: string;
  width: number;
  height: number;
  depth: number;
  offset?: BABYLON.Vector3;
  parent?: BABYLON.Node;
};

export default class MarkUtils {
  private static scene: BABYLON.Scene;
  private static lineWidth = 3;
  private static lineMaterial: BABYLON.StandardMaterial;
  private static fullMaterial: BABYLON.StandardMaterial;

  public static initialize(scene: BABYLON.Scene): void {
    MarkUtils.scene = scene;
    MarkUtils.lineMaterial = new BABYLON.StandardMaterial('mark:line', scene);
    MarkUtils.lineMaterial.diffuseColor = BABYLON.Color3.Black();
    MarkUtils.lineMaterial.specularColor = BABYLON.Color3.Black();
    MarkUtils.lineMaterial.emissiveColor = BasicUtils.color(48, 160, 220);
    MarkUtils.lineMaterial.disableLighting = true;
    // MarkUtils.lineMaterial.wireframe = true;
    MarkUtils.lineMaterial.freeze();

    MarkUtils.fullMaterial = new BABYLON.StandardMaterial('mark:full', scene);
    MarkUtils.fullMaterial.diffuseColor = BABYLON.Color3.Black();
    MarkUtils.fullMaterial.specularColor = BABYLON.Color3.Black();
    MarkUtils.fullMaterial.emissiveColor = BasicUtils.color(48, 160, 220);
    MarkUtils.fullMaterial.alpha = 0.4;
    // MarkUtils.fullMaterial.alphaMode = BABYLON.Engine.ALPHA_ADD;
    MarkUtils.fullMaterial.disableLighting = true;
    // MarkUtils.fullMaterial.wireframe = true;
    MarkUtils.fullMaterial.freeze();
  }

  public static buildMark(
    settings: MarkSettings,
    scene: BABYLON.Scene = MarkUtils.scene
  ): BABYLON.TransformNode {
    const container = new BABYLON.TransformNode(settings.name || 'mark', scene);
    const height = settings.height + MarkUtils.lineWidth / 2;
    const meshes = [];
    // center
    const centerPart = LineUtils.drawLine(
      null,
      {
        path: [
          new BABYLON.Vector3(0, 0, 0),
          new BABYLON.Vector3(0, 0, -settings.depth),
          new BABYLON.Vector3(settings.width, 0, -settings.depth),
          new BABYLON.Vector3(settings.width, 0, 0)
        ],
        material: MarkUtils.lineMaterial,
        closed: true,
        width: MarkUtils.lineWidth
      },
      scene
    );
    centerPart.parent = container;
    centerPart.position = new BABYLON.Vector3(0, height, 0);
    meshes.push(centerPart);

    // center bottom
    const centerBottomPart = LineUtils.drawLine(
      null,
      {
        path: [
          new BABYLON.Vector3(0, 0, 0),
          new BABYLON.Vector3(0, 0, -settings.depth),
          new BABYLON.Vector3(settings.width, 0, -settings.depth),
          new BABYLON.Vector3(settings.width, 0, 0)
        ],
        material: MarkUtils.lineMaterial,
        closed: true,
        width: MarkUtils.lineWidth
      },
      scene
    );
    centerBottomPart.parent = container;
    centerBottomPart.position = new BABYLON.Vector3(0, 0.25, 0);
    meshes.push(centerBottomPart);

    // top left
    const topLeft1 = LineUtils.drawLine(
      null,
      {
        path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, height)],
        material: MarkUtils.lineMaterial,
        width: MarkUtils.lineWidth
      },
      scene
    );
    topLeft1.parent = container;
    topLeft1.rotate(BABYLON.Vector3.Right(), -Math.PI / 2);
    topLeft1.rotate(BABYLON.Vector3.Backward(), Math.PI / 2);
    topLeft1.position = new BABYLON.Vector3(-MarkUtils.lineWidth / 2, 0, 0);
    meshes.push(topLeft1);

    const topLeft2 = LineUtils.drawLine(
      null,
      {
        path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, height)],
        material: MarkUtils.lineMaterial,
        width: MarkUtils.lineWidth
      },
      scene
    );
    topLeft2.parent = container;
    topLeft2.rotate(BABYLON.Vector3.Right(), -Math.PI / 2);
    topLeft2.position = new BABYLON.Vector3(0, 0, MarkUtils.lineWidth / 2);
    meshes.push(topLeft2);

    // top right
    const topRight1 = LineUtils.drawLine(
      null,
      {
        path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, height)],
        material: MarkUtils.lineMaterial,
        width: MarkUtils.lineWidth
      },
      scene
    );
    topRight1.parent = container;
    topRight1.rotate(BABYLON.Vector3.Right(), -Math.PI / 2);
    topRight1.rotate(BABYLON.Vector3.Backward(), Math.PI / 2);
    topRight1.position = new BABYLON.Vector3(settings.width + MarkUtils.lineWidth / 2, 0, 0);
    meshes.push(topRight1);

    const topRight2 = LineUtils.drawLine(
      null,
      {
        path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, height)],
        material: MarkUtils.lineMaterial,
        width: MarkUtils.lineWidth
      },
      scene
    );
    topRight2.parent = container;
    topRight2.rotate(BABYLON.Vector3.Right(), -Math.PI / 2);
    topRight2.position = new BABYLON.Vector3(settings.width, 0, MarkUtils.lineWidth / 2);
    meshes.push(topRight2);

    // bottom right
    const bottomRight1 = LineUtils.drawLine(
      null,
      {
        path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, height)],
        material: MarkUtils.lineMaterial,
        width: MarkUtils.lineWidth
      },
      scene
    );
    bottomRight1.parent = container;
    bottomRight1.rotate(BABYLON.Vector3.Right(), -Math.PI / 2);
    bottomRight1.rotate(BABYLON.Vector3.Backward(), Math.PI / 2);
    bottomRight1.position = new BABYLON.Vector3(
      settings.width + MarkUtils.lineWidth / 2,
      0,
      -settings.depth
    );
    meshes.push(bottomRight1);

    const bottomRight2 = LineUtils.drawLine(
      null,
      {
        path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, height)],
        material: MarkUtils.lineMaterial,
        width: MarkUtils.lineWidth
      },
      scene
    );
    bottomRight2.parent = container;
    bottomRight2.rotate(BABYLON.Vector3.Right(), -Math.PI / 2);
    bottomRight2.position = new BABYLON.Vector3(
      settings.width,
      0,
      -settings.depth - MarkUtils.lineWidth / 2
    );
    meshes.push(bottomRight2);

    // bottom left
    const bottomLeft1 = LineUtils.drawLine(
      null,
      {
        path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, height)],
        material: MarkUtils.lineMaterial,
        width: MarkUtils.lineWidth
      },
      scene
    );
    bottomLeft1.parent = container;
    bottomLeft1.rotate(BABYLON.Vector3.Right(), -Math.PI / 2);
    bottomLeft1.rotate(BABYLON.Vector3.Backward(), Math.PI / 2);
    bottomLeft1.position = new BABYLON.Vector3(-MarkUtils.lineWidth / 2, 0, -settings.depth);
    meshes.push(bottomLeft1);

    const bottomLeft2 = LineUtils.drawLine(
      null,
      {
        path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, height)],
        material: MarkUtils.lineMaterial,
        width: MarkUtils.lineWidth
      },
      scene
    );
    bottomLeft2.parent = container;
    bottomLeft2.rotate(BABYLON.Vector3.Right(), -Math.PI / 2);
    bottomLeft2.position = new BABYLON.Vector3(0, 0, -settings.depth - MarkUtils.lineWidth / 2);
    meshes.push(bottomLeft2);

    const finalMesh = BABYLON.Mesh.MergeMeshes(meshes);
    finalMesh.name = 'mark-mesh';
    finalMesh.parent = container;

    const offset = settings.offset || BABYLON.Vector3.Zero();
    container.position = offset;
    container.parent = settings.parent;

    return container;
  }

  public static buildFullMark(
    settings: MarkSettings,
    scene: BABYLON.Scene = MarkUtils.scene
  ): BABYLON.TransformNode {
    const container = new BABYLON.TransformNode(settings.name || 'mark-full', scene);
    const height = settings.height + MarkUtils.lineWidth / 2;
    const width = settings.width + MarkUtils.lineWidth;
    const depth = settings.depth + MarkUtils.lineWidth;
    const meshes = [];

    const top = BABYLON.GroundBuilder.CreateGround(
      null,
      {
        width: width,
        height: depth
      },
      scene
    );
    top.material = MarkUtils.fullMaterial;
    top.isPickable = false;
    top.parent = container;
    top.position = new BABYLON.Vector3(width / 2, height, -depth / 2);
    meshes.push(top);

    const back = BABYLON.PlaneBuilder.CreatePlane(
      null,
      {
        width: width,
        height: height
      },
      scene
    );
    back.material = MarkUtils.fullMaterial;
    back.isPickable = false;
    back.parent = container;
    back.position = new BABYLON.Vector3(width / 2, height / 2, 0);
    back.rotate(BABYLON.Vector3.Right(), Math.PI);
    meshes.push(back);

    const front = BABYLON.PlaneBuilder.CreatePlane(
      null,
      {
        width: width,
        height: height
      },
      scene
    );
    front.material = MarkUtils.fullMaterial;
    front.isPickable = false;
    front.parent = container;
    front.position = new BABYLON.Vector3(width / 2, height / 2, -depth);
    meshes.push(front);

    const left = BABYLON.PlaneBuilder.CreatePlane(
      null,
      {
        width: depth,
        height: height
      },
      scene
    );
    left.material = MarkUtils.fullMaterial;
    left.isPickable = false;
    left.parent = container;
    left.position = new BABYLON.Vector3(0, height / 2, -depth / 2);
    left.rotate(BABYLON.Vector3.Up(), Math.PI / 2);
    meshes.push(left);

    const right = BABYLON.PlaneBuilder.CreatePlane(
      null,
      {
        width: depth,
        height: height
      },
      scene
    );
    right.material = MarkUtils.fullMaterial;
    right.isPickable = false;
    right.parent = container;
    right.position = new BABYLON.Vector3(width, height / 2, -depth / 2);
    right.rotate(BABYLON.Vector3.Down(), Math.PI / 2);
    meshes.push(right);

    const finalMesh = BABYLON.Mesh.MergeMeshes(meshes);
    finalMesh.name = 'mark-full-mesh';
    finalMesh.parent = container;

    const offset = settings.offset || BABYLON.Vector3.Zero();
    container.position = offset.add(
      new BABYLON.Vector3(-MarkUtils.lineWidth / 2, 0, MarkUtils.lineWidth / 2)
    );
    container.parent = settings.parent;

    return container;
  }
}
