import React, { FC, RefObject } from 'react';

// Components
import { EditProperty } from 'components';

// Types:

// Babylon:
import PreviewBabylonScene from 'components/babylon/PreviewBabylonScene';
import LoaderUtils from 'components/babylon/util/LoaderUtils';
import { SceneEventArgs, SceneMountReturn } from 'components/babylon/BabylonScene';
import DeviceNode from 'components/babylon/node/DeviceNode';

// Types
import { Device, Model, Type, DeviceComponent, Description } from 'types/Device';

interface Props {
  components: DeviceComponent[];
  description: Description;
  device: Device;
  id: string;
  model: Model;
  previewDepth: number;
  previewObject: DeviceNode;
  sceneRef: RefObject<PreviewBabylonScene>;
  setPreviewObject: (p: DeviceNode) => void;
  type: Type;
  setPreviewDepth: (d: number) => void;
}

// ======================================================================================================

const DevicePreview: FC<Props> = ({
  components,
  description,
  device,
  id,
  model,
  previewDepth,
  previewObject,
  sceneRef,
  setPreviewObject,
  setPreviewDepth,
  type
}) => {
  const refreshPreview = (depth?: number, extendCover?: boolean, buildBaseCorpus?: boolean) => {
    if (previewObject && !previewObject.isDisposed()) {
      previewObject.dispose();
    }
    const deviceNode = new DeviceNode({
      id: id,
      width: model.width,
      left: true,
      right: true,
      depth: depth || previewDepth,
      bottom: 'Feet',
      device,
      border: model.modular || model.modularNOL ? 'BorderTrench' : 'BorderAngular'
    });
    // if (typeof buildBaseCorpus === 'undefined' ? model.buildBaseCorpus : buildBaseCorpus) {
    //   if (model.modularNOL) {
    //     LoaderUtils.loadBaseNOL(deviceNode, node => {
    //       node.bake();
    //     });
    //   } else {
    //     LoaderUtils.loadBase(deviceNode, node => {
    //       node.bake();
    //     });
    //   }
    // }

    // const toLoad = components.length + (type === Type.Device ? 1 : 0);
    // let loaded = 0;
    // for (let i = 0; i < components.length; i++) {
    //   const component = components[i];
    //   LoaderUtils.loadComponent(
    //     deviceNode,
    //     component,
    //     _node => {
    //       if (++loaded >= toLoad) deviceNode.bake();
    //     },
    //     () => {
    //       if (++loaded >= toLoad) deviceNode.bake();
    //     }
    //   );
    // }
    // if (type === Type.Device) {
    //   LoaderUtils.loadDevice(
    //     deviceNode,
    //     _node => {
    //       if (++loaded >= toLoad) deviceNode.bake();
    //     },
    //     () => {
    //       if (++loaded >= toLoad) deviceNode.bake();
    //     }
    //   );
    // }

    deviceNode.position.x = -model.width / 20;
    deviceNode.position.z = -previewDepth / 20;

    if (sceneRef && sceneRef.current) sceneRef.current.setPreview(deviceNode);

    setPreviewObject(deviceNode);
  };

  const onSceneMounted = (e: SceneEventArgs) => {
    const sceneMountReturn: SceneMountReturn = {
      resizeEvents: new Array<() => void>()
    };
    // const { canvas, scene, engine } = e;

    refreshPreview();

    return sceneMountReturn;
  };

  // ======================================================================================================

  return (
    <div className="Device-Preview" style={{ marginTop: '2.5rem' }}>
      <PreviewBabylonScene onSceneMounted={onSceneMounted} ref={sceneRef}>
        <EditProperty description="Preview Depth:">
          <form action="">
            <select
              onChange={event => {
                const n = Number(event.target.value);
                setPreviewDepth(n);
                refreshPreview(n);
              }}
              id="Device-Type-Selectbox"
              value={previewDepth}
            >
              {description.name700 && description.name700.length > 0 && <option value={700}>700</option>}
              {description.name850 && description.name850.length > 0 && <option value={850}>850</option>}
            </select>
          </form>
        </EditProperty>
      </PreviewBabylonScene>
    </div>
  );
};

export default DevicePreview;
