import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ReactTable from 'react-table';

// Components:
import { Button, LoadingSpinner } from 'components';

// Styles:
import './style.css';
import 'react-table/react-table.css';

// Utils:
import { post } from 'utils/AJAX';

// =====================================================

interface Props extends RouteComponentProps {}

// =====================================================

const Users: FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const ROWS = 11;
  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [results, setResults] = useState(ROWS);

  // =================================

  const columns = [
    {
      Header: 'E-Mail',
      accessor: 'email',
    },
    {
      id: 'admin',
      Header: 'Admin',
      accessor: (u: any) => {
        if (u.rights) {
          return u.rights.map((r: any, index: number) =>
            index < u.rights.length - 1 ? `${r}, ` : `${r}`
          );
        }
      },
    },
  ];

  const fetchUsers = async () => {
    setLoading(true);

    const { data, error, count } = await post(
      `${process.env.REACT_APP_API_URL}/member/search`,
      {
        data: {
          rows: ROWS,
          offset,
          query: query,
          sort: {
            field: 'email',
            order: 'ASC',
          },
        },
      }
    );
    if (count) {
      setResults(count);
    }
    if (data) {
      /* if (offset >= 1) setUsers([...users, ...data]);
      else  */ setUsers(
        data
      );
    }
    if (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // =================================

  useEffect(() => {
    fetchUsers();
  }, [offset]);

  useEffect(() => {
    setOffset(0);
  }, [query]);

  return (
    <div className="Users">
      <div>
        <h1>Users</h1>
      </div>
      {loading && <LoadingSpinner />}

      <div className="Users-Options">
        <div className="Users-Options-Searchform">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              fetchUsers();
            }}
          >
            <input
              className="Users-Options-Searchform-Textfield"
              onChange={(event) => {
                setQuery(event.target.value);
              }}
              placeholder="Filter Users"
              type="search"
              value={query}
            />
            {/* <Button btnType="second" type="submit">
              Apply
            </Button> */}
          </form>
        </div>

        <Button btnType="first" onClick={() => history.push('/users/new')}>
          Create User
        </Button>
      </div>
      <div className="Users-Content">
        <ReactTable
          columns={columns}
          data={users}
          resolveData={(data) => {
            const formatted = [];
            for (let index = 0; index < offset; index++) {
              if (index < offset) {
                formatted.push({ translations: { vdrqR: { name: '' } } });
              }
            }
            formatted.push(...data);
            if (query === '') {
              for (let index = formatted.length; index < results; index++) {
                formatted.push({ translations: { vdrqR: { name: '' } } });
              }
            }
            return formatted;
          }}
          defaultPageSize={10}
          showPageSizeOptions={false}
          getTdProps={(
            state: any,
            rowInfo: any,
            column: any,
            instance: any
          ) => {
            return {
              onClick: (e: any, handleOriginal: any) => {
                if (rowInfo) {
                  history.push(`/users/${rowInfo.original.id}`);
                }
              },
            };
          }}
          onPageChange={(page) => {
            if (offset <= results) {
              setOffset(page * 10);
            }
          }}
        />
      </div>
    </div>
  );
};

export default Users;
