import { Subtype } from 'types/DeviceEnum';

export * from './DeviceEnum';

export enum Type {
  Device,
  Equipment,
  Option
}

export enum EnergySource {
  Neutral,
  Electro,
  Gas,
  Steam
}

export type Device = {
  id?: string;
  category?: Category;
  code: string;
  type: Type;
  subtype?: Subtype;
  style?: string;
  description: Description;
  translations: Map<string, Translation>;
  model: Model;
  energy: Energy;
  price: number;
  dependency: Dependency;
  components: Array<DeviceComponent>;
};

export type Category = {
  id: string;
};

export type Description = {
  norm: string;
  name700?: string;
  name850?: string;
};

export type Translation = {
  name: string;
  description: string;
};

export type Model = {
  width: number;
  widthSubstructure: number;
  height: number;
  depths: Array<number>;
  marineMeister: boolean;
  masterline: boolean;
  modular: boolean;
  modularNOL: boolean;
  spaceCombi: boolean;
  flexiChef: boolean;
  buildBaseCorpus: boolean;
  extendCover: boolean;
  extendCoverMoveBack?: boolean;
  extendCoverSize?: number;
  modularBorderIncluded?: boolean;
  modularSink: boolean;
};

export type Energy = {
  source: EnergySource;
  elektro?: number;
  gas?: number;
  steam?: number;
  voltage?: number;
  electroConditions?: string;
};

export type Dependency = {
  expandableRight: boolean;
  expandableLeft: boolean;
  coverEnlargement: boolean;
  coverEnlargementSize: number;
  colorBlend: boolean;
  equipmentCombinations: Array<Subtype>;
};

export type DeviceComponent = {
  component: Component;
  position: Vector3;
  scaling: Vector3;
  fixed?: boolean;
  backside?: boolean;
};

export type Vector3 = {
  x: number;
  y: number;
  z: number;
};

export type Component = {
  id: string;
};

export default Device;
