import React, { FC } from 'react';

// Style
import './style.css';
import { DeviceView } from '../../../types/DeviceView';

interface Props {
  setView: (view: DeviceView) => void;
  view: DeviceView;
}

const SubNav: FC<Props> = ({ setView, view }) => {
  return (
    <div className="Device-SubNav">
      <button
        className={`Device-SubNav-Item${view === 'general' ? '-active' : ''}`}
        onClick={() => setView('general')}
      >
        General
      </button>
      <button
        className={`Device-SubNav-Item${
          view === 'translations' ? '-active' : ''
        }`}
        onClick={() => setView('translations')}
      >
        Translations
      </button>
      <button
        className={`Device-SubNav-Item${view === 'model' ? '-active' : ''}`}
        onClick={() => setView('model')}
      >
        Model
      </button>
      <button
        className={`Device-SubNav-Item${
          view === 'dependencies' ? '-active' : ''
        }`}
        onClick={() => setView('dependencies')}
      >
        Dependencies
      </button>
      <button
        className={`Device-SubNav-Item${view === 'media' ? '-active' : ''}`}
        onClick={() => setView('media')}
      >
        Media
      </button>
      <button
        className={`Device-SubNav-Item${
          view === 'components' ? '-active' : ''
        }`}
        onClick={() => setView('components')}
      >
        Components
      </button>
      <button
        className={`Device-SubNav-Item${view === 'dxf' ? '-active' : ''}`}
        onClick={() => setView('dxf')}
      >
        DXF
      </button>
    </div>
  );
};

export default SubNav;
