// Atoms:
import { AShareDialog, ASimple, Button, Icon, LoadingSpinner } from 'components'
import React, { FC, useState } from 'react'
import { Portal } from 'react-portal'
import { PoseGroup } from 'react-pose'
// Styles:
import './style.scss'

// ===================================================================
interface Props {
  onAbort: () => void
  open: boolean
  onConfirm: () => void
  heading: string
  text: string
}

// ===================================================================

export const Dialog: FC<Props> = ({ onAbort, open, onConfirm, heading, text }) => {
  const [loading] = useState<boolean>(false)

  /* Render */
  return (
    <Portal>
      <PoseGroup flipMove={false}>
        {open && (
          <ASimple
            key='DeleteConfirm-Background'
            className='DeleteConfirm-Background'
            style={{ height: window.innerHeight }}
            onClick={onAbort}
          />
        )}

        {open && (
          <AShareDialog
            key='DeleteConfirm-Content'
            className='DeleteConfirm-Content'
            topExit='0'
            topEnter='50%'
          >
            <div className='DeleteConfirm-Content-Icon'>
              <Icon type='close' size='1.25rem' color='white' onClick={onAbort} />
            </div>

            <div className='DeleteConfirm-Content-Main'>
              <div>
                <h3 className='DeleteConfirm-Content-Main-Headline'>{heading}</h3>

                <p className='DeleteConfirm-Content-Main-Question'>{text}</p>

                <Button btnType='third' onClick={onConfirm} style={{ marginBottom: '1rem' }}>
                  {loading ? <LoadingSpinner size='1rem' /> : 'Confirm'}
                </Button>
                <Button btnType='fourth' onClick={onAbort}>
                  {loading ? <LoadingSpinner size='1rem' /> : 'Cancel'}
                </Button>
              </div>
            </div>
          </AShareDialog>
        )}
      </PoseGroup>
    </Portal>
  )
}
