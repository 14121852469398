import * as BABYLON from 'babylonjs';
import BasicUtils from './BasicUtils';

export default class MaterialUtils {
  public static MATERIAL_METAL: BABYLON.PBRMaterial;
  public static MATERIAL_METAL_COLORED: BABYLON.PBRMaterial;
  public static MATERIAL_MASTER_KNOB: BABYLON.PBRMaterial;
  public static MATERIAL_WALL: BABYLON.MultiMaterial;
  public static MATERIAL_TRANSPARENT: BABYLON.StandardMaterial;
  public static MATERIAL_WALL_TOPPING_WINDOW: BABYLON.StandardMaterial;

  /**
   * Attach all default Materials to the Scene
   */
  public static initialize(scene: BABYLON.Scene): void {
    // Import Materials
    {
      const material = new BABYLON.PBRMaterial('_metal', scene);
      material.albedoColor = BasicUtils.color(155, 155, 160);
      material.metallic = 0.8;
      material.roughness = 0.1;
      material.backFaceCulling = false;
      material.clearCoat.isEnabled = true;
      material.clearCoat.intensity = 0.2;
      material.clearCoat.roughness = 0.1;
      material.clearCoat.indexOfRefraction = 3;
      material.specularIntensity = 0.15;
      material.directIntensity = 1;
      material.enableSpecularAntiAliasing = true;
      //material.freeze();
      MaterialUtils.MATERIAL_METAL = material;
      MaterialUtils.MATERIAL_METAL.clone('_metal_blue_panel');
      MaterialUtils.MATERIAL_METAL.clone('_metal_blue_panel_top');
      MaterialUtils.MATERIAL_METAL.clone('_metal_side');
    }
    {
      const material = new BABYLON.PBRMaterial('_metal_error', scene);
      // material.albedoColor = BasicUtils.color(160, 60, 60);
      material.albedoColor = BasicUtils.color(155, 155, 160);
      material.metallic = 0.8;
      material.roughness = 0.1;
      material.backFaceCulling = false;
      material.clearCoat.isEnabled = true;
      material.clearCoat.intensity = 0.2;
      material.clearCoat.roughness = 0.1;
      material.clearCoat.indexOfRefraction = 3;
      material.specularIntensity = 0.15;
      material.directIntensity = 1;
      material.enableSpecularAntiAliasing = true;
      //material.freeze();
    }
    {
      const material = new BABYLON.PBRMaterial('_metal_blue', scene);
      material.albedoColor = BasicUtils.color(0, 63, 127);
      material.metallic = 0.6;
      material.roughness = 0.15;
      material.backFaceCulling = false;
      material.clearCoat.isEnabled = true;
      material.clearCoat.intensity = 0.2;
      material.clearCoat.roughness = 0.1;
      material.clearCoat.indexOfRefraction = 3;
      material.environmentIntensity = 0.5;
      material.specularIntensity = 0.15;
      material.directIntensity = 1;
      material.enableSpecularAntiAliasing = true;
      //material.freeze();
    }
    {
      const material = new BABYLON.PBRMaterial('_metal_blue_knob', scene);
      material.albedoColor = BasicUtils.color(25, 49, 83);
      material.metallic = 0.6;
      material.roughness = 0.15;
      material.backFaceCulling = false;
      material.clearCoat.isEnabled = true;
      material.clearCoat.intensity = 0.2;
      material.clearCoat.roughness = 0.1;
      material.clearCoat.indexOfRefraction = 3;
      material.environmentIntensity = 0.5;
      material.specularIntensity = 0.15;
      material.directIntensity = 1;
      material.enableSpecularAntiAliasing = true;
      //material.freeze();
    }
    {
      const material = new BABYLON.PBRMaterial('_metal_blue_PCover', scene);
      material.albedoColor = BasicUtils.color(155, 155, 160);
      material.metallic = 0.7;
      material.roughness = 0.1;
      material.backFaceCulling = false;
      material.clearCoat.isEnabled = true;
      material.clearCoat.intensity = 0.2;
      material.clearCoat.roughness = 0.1;
      material.clearCoat.indexOfRefraction = 3;
      material.specularIntensity = 0.15;
      material.directIntensity = 1;
      material.enableSpecularAntiAliasing = true;
      //material.freeze();
    }
    {
      const material = new BABYLON.PBRMaterial('_metal_substructure', scene);
      material.albedoColor = BasicUtils.color(155, 155, 160);
      material.metallic = 0.8;
      material.roughness = 0.1;
      material.backFaceCulling = false;
      material.clearCoat.isEnabled = true;
      material.clearCoat.intensity = 0.2;
      material.clearCoat.roughness = 0.1;
      material.clearCoat.indexOfRefraction = 3;
      material.specularIntensity = 0.15;
      material.directIntensity = 1;
      material.enableSpecularAntiAliasing = true;
      //material.freeze();
      MaterialUtils.MATERIAL_METAL = material;
    }
    {
      const material = new BABYLON.StandardMaterial('_metal_salamander', scene);
      material.diffuseColor = BasicUtils.color(155, 155, 160);
      material.emissiveColor = material.diffuseColor;
      material.sideOrientation = BABYLON.Material.ClockWiseSideOrientation;
      material.alpha = 0.25;
      material.alphaMode = BABYLON.Engine.ALPHA_PREMULTIPLIED;
      //material.freeze();
    }
    {
      const material = new BABYLON.StandardMaterial('_metal_blue_salamander', scene);
      material.diffuseColor = BasicUtils.color(0, 63, 127);
      material.emissiveColor = material.diffuseColor;
      material.sideOrientation = BABYLON.Material.ClockWiseSideOrientation;
      material.alpha = 0.25;
      material.alphaMode = BABYLON.Engine.ALPHA_PREMULTIPLIED;
      //material.freeze();
    }
    {
      const material = new BABYLON.PBRMaterial('_plastic', scene);
      material.albedoColor = BasicUtils.color(5, 5, 5);
      material.metallic = 0;
      material.roughness = 0.6;
      material.backFaceCulling = false;
      material.environmentIntensity = 0.5;
      material.enableSpecularAntiAliasing = true;
      //material.freeze();
    }
    {
      const material = new BABYLON.StandardMaterial('_plastic_salamander', scene);
      material.diffuseColor = BasicUtils.color(5, 5, 5);
      material.emissiveColor = material.diffuseColor;
      material.sideOrientation = BABYLON.Material.ClockWiseSideOrientation;
      material.alpha = 0.25;
      material.alphaMode = BABYLON.Engine.ALPHA_PREMULTIPLIED;
      //material.freeze();
    }
    {
      const material = new BABYLON.PBRMaterial('_masterknob', scene);
      MaterialUtils.MATERIAL_MASTER_KNOB = material;
      MaterialUtils.masterknob(false);
    }
    {
      const material = new BABYLON.PBRMaterial('_red', scene);
      material.albedoColor = BasicUtils.color(160, 15, 15);
      material.metallic = 0;
      material.roughness = 0.7;
      material.backFaceCulling = false;
      material.environmentIntensity = 0.2;
      //material.freeze();
    }
    {
      const material = new BABYLON.PBRMaterial('_yellow', scene);
      material.albedoColor = BasicUtils.color(220, 195, 0);
      material.metallic = 0;
      material.roughness = 0.7;
      material.backFaceCulling = false;
      material.environmentIntensity = 0.2;
      //material.freeze();
    }
    {
      const material = new BABYLON.PBRMaterial('_orange', scene);
      material.albedoColor = BasicUtils.color(255, 165, 0);
      material.metallic = 0;
      material.roughness = 0.7;
      material.backFaceCulling = false;
      material.environmentIntensity = 0.2;
      // material.freeze();
    }
    {
      const material = new BABYLON.PBRMaterial('_blue_bright', scene);
      material.albedoColor = BasicUtils.color(0, 63, 127);
      material.metallic = 0;
      material.roughness = 0.7;
      material.backFaceCulling = false;
      material.environmentIntensity = 0.2;
      //material.freeze();
    }
    {
      const material = new BABYLON.PBRMaterial('_ceramic', scene);
      material.albedoColor = BasicUtils.color(210, 210, 210);
      material.metallic = 0.6;
      material.roughness = 0.3;
      material.backFaceCulling = false;
      material.sheen.isEnabled = true;
      material.environmentIntensity = 0.1;
      //material.freeze();
    }
    {
      const material = new BABYLON.PBRMaterial('_glas', scene);
      material.albedoColor = BasicUtils.color(21, 21, 21);
      material.metallic = 0.9;
      material.roughness = 0.1;
      material.backFaceCulling = false;
      material.clearCoat.isEnabled = true;
      material.clearCoat.intensity = 0.2;
      material.clearCoat.roughness = 0.1;
      material.clearCoat.indexOfRefraction = 3;
      material.environmentIntensity = 0.1;
      //material.freeze();
    }
    {
      const material = new BABYLON.PBRMaterial('_glas_transp', scene);
      material.albedoColor = BasicUtils.color(21, 21, 21);
      material.metallic = 0.9;
      material.roughness = 0.1;
      material.backFaceCulling = false;
      material.clearCoat.isEnabled = true;
      material.clearCoat.intensity = 0.2;
      material.clearCoat.roughness = 0.1;
      material.clearCoat.indexOfRefraction = 3;
      material.environmentIntensity = 0.1;
      material.alpha = 0.1;
      //material.freeze();
    }
    // Displays
    const displays = [
      'display_cd',
      'display_cd2',
      'display_fc',
      'display_fc_m',
      'display_salamander',
      'display_sc',
      'display_scj',
      'display_sct',
      'display_school',
      'folie_scl',
      'folie_scm',
      'folie_scr1',
      'folie_scr2',
      'folie',
      'folie_spaceclean1',
      'folie_spaceclean1_off',
      'folie_spaceclean1_m',
      'folie_spaceclean1_off_m'
    ];
    for (let i = 0; i < displays.length; i++) {
      const display = displays[i];
      const material = new BABYLON.StandardMaterial(`_${display}`, scene);
      material.diffuseColor = BABYLON.Color3.Black();
      material.specularColor = BABYLON.Color3.Black();
      material.emissiveColor = BABYLON.Color3.White();
      material.ambientTexture = new BABYLON.Texture(`/texture/${display}.jpg`, scene);
      material.backFaceCulling = false;
      material.freeze();
    }
    // ---
    {
      const material = new BABYLON.StandardMaterial('_blackplane', scene);
      material.diffuseColor = BABYLON.Color3.Black();
      material.ambientColor = material.diffuseColor;
      material.specularColor = material.diffuseColor;
      material.emissiveColor = material.diffuseColor;
      material.backFaceCulling = false;
      material.freeze();
    }

    // Drop materials
    {
      const material = new BABYLON.StandardMaterial('drop_green', scene);
      material.diffuseColor = BABYLON.Color3.Black();
      material.specularColor = BABYLON.Color3.Black();
      material.emissiveColor = BasicUtils.color(0, 205, 0);
      material.alpha = 0.666;
      material.disableLighting = true;
      material.freeze();
    }
    {
      const material = new BABYLON.StandardMaterial('drop_red', scene);
      material.diffuseColor = BABYLON.Color3.Black();
      material.specularColor = BABYLON.Color3.Black();
      material.emissiveColor = BasicUtils.color(255, 64, 64);
      material.alpha = 0.666;
      material.disableLighting = true;
      material.freeze();
    }

    // Merge Mark Label
    {
      const material = new BABYLON.StandardMaterial('_connect_label', scene);
      material.diffuseColor = BABYLON.Color3.Black();
      material.specularColor = BABYLON.Color3.Black();
      material.emissiveColor = BasicUtils.color(48, 160, 220);
      material.opacityTexture = new BABYLON.Texture('texture/connect.png', scene);
      material.alpha = 0.666;
      material.disableLighting = true;
      material.freeze();
    }
    // Wall
    {
      const material = new BABYLON.MultiMaterial('_wall', scene);
      const white = new BABYLON.StandardMaterial('_wall_white', scene);
      white.diffuseColor = BasicUtils.color(20, 20, 20);
      white.ambientColor = BABYLON.Color3.White();
      white.specularColor = BABYLON.Color3.Black();
      white.emissiveColor = BasicUtils.color(165, 165, 165);
      white.backFaceCulling = true;
      //white.freeze();
      const black = new BABYLON.StandardMaterial('_wall_black', scene);
      black.diffuseColor = BABYLON.Color3.Black();
      black.ambientColor = black.diffuseColor;
      black.specularColor = black.diffuseColor;
      black.emissiveColor = black.diffuseColor;
      black.backFaceCulling = true;
      //black.freeze();
      material.subMaterials.push(white, black);

      MaterialUtils.MATERIAL_WALL = material;

      const grayWall = new BABYLON.MultiMaterial('_wall', scene);
      const gray = new BABYLON.StandardMaterial('_wall_gray', scene);
      gray.diffuseColor = BasicUtils.color(20, 20, 20);
      gray.ambientColor = BasicUtils.color(120, 120, 120);
      gray.specularColor = BABYLON.Color3.Black();
      gray.emissiveColor = BasicUtils.color(40, 40, 40);
      gray.backFaceCulling = true;

      grayWall.subMaterials.push(gray, black);
    }
    {
      const material = new BABYLON.StandardMaterial('_wall_topping_window', scene);
      material.diffuseColor = BasicUtils.color(20, 20, 20);
      material.ambientColor = BABYLON.Color3.White();
      material.specularColor = BABYLON.Color3.Black();
      material.emissiveColor = BasicUtils.color(185, 185, 185);
      material.disableLighting = true;
      material.backFaceCulling = true;
      material.freeze();

      MaterialUtils.MATERIAL_WALL_TOPPING_WINDOW = material;
    }
    {
      const material = new BABYLON.StandardMaterial('_transparent', scene);
      material.alpha = 0;
      material.alphaMode = BABYLON.Engine.ALPHA_PREMULTIPLIED;
      material.freeze();
      MaterialUtils.MATERIAL_TRANSPARENT = material;
    }
  }

  public static masterknob(steel?: boolean) {
    const material = MaterialUtils.MATERIAL_MASTER_KNOB;
    if (steel) {
      material.albedoColor = BasicUtils.color(155, 155, 160);
      material.metallic = 0.8;
      material.roughness = 0.1;
      material.backFaceCulling = false;
      material.clearCoat.isEnabled = true;
      material.clearCoat.intensity = 0.2;
      material.clearCoat.roughness = 0.1;
      material.clearCoat.indexOfRefraction = 3;
      material.environmentIntensity = 1;
      material.specularIntensity = 0.15;
      material.enableSpecularAntiAliasing = true;
    } else {
      material.albedoColor = BasicUtils.color(5, 5, 5);
      material.metallic = 0;
      material.roughness = 0.6;
      material.backFaceCulling = false;
      material.clearCoat.isEnabled = false;
      material.environmentIntensity = 0.5;
      material.specularIntensity = 1;
      material.enableSpecularAntiAliasing = true;
    }
  }
}
