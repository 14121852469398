import React, { FC } from 'react';

import './style.scss';

interface Props {
  checked: boolean;
  label: string;
  onChange: () => void;
  value: any;
}

export const RadioButton: FC<Props> = ({ checked, label, onChange, value }) => {
  return (
    <label className={'Radio-Label'}>
      <input checked={checked} onChange={onChange} type="radio" value={value} />
      <span></span>
      {label}
    </label>
  );
};
