import React, { FC } from 'react'

// Components
import EquipmentCombinations from '../EquipmentCombinations'

// Types:
import { Dependency } from 'types/Device'
import { Subtype } from 'types/DeviceEnum'
import { EditProperty } from '../../../components/EditProperty/index'

interface Props {
  dependency: Dependency
  newEquipment: Subtype
  setDependency: (d: Dependency) => void
  setNewEquipment: (n: Subtype) => void
}

const Dependencies: FC<Props> = ({ dependency, setDependency, newEquipment, setNewEquipment }) => {
  return (
    <>
      <h3 className='Device-SubCategory-Header'>Dependencies</h3>
      <div className='Device-SubCategory'>
        <div className='Device-Checkboxes-Wrapper'>
          <div className='Device-Checkboxes-Wrapper-Box'>
            <input
              checked={dependency.expandableRight}
              id='expandableRightCB'
              onChange={(e) =>
                setDependency({
                  ...dependency,
                  expandableRight: e.target.checked,
                })
              }
              type='checkbox'
            />
            <label htmlFor='expandableRightCB'>Expandable Right</label>
          </div>
          <div className='Device-Checkboxes-Wrapper-Box'>
            <input
              checked={dependency.expandableLeft}
              id='expandableLeftCB'
              onChange={(e) =>
                setDependency({
                  ...dependency,
                  expandableLeft: e.target.checked,
                })
              }
              type='checkbox'
            />
            <label htmlFor='expandableLeftCB'>Expandable Left</label>
          </div>
          <div className='Device-Checkboxes-Wrapper-Box'>
            <input
              checked={dependency.coverEnlargement}
              id='coverEnlargementCB'
              onChange={(e) =>
                setDependency({
                  ...dependency,
                  coverEnlargement: e.target.checked,
                })
              }
              type='checkbox'
            />
            <label htmlFor='coverEnlargementCB'>Cover Enlargement</label>
          </div>

          <div className='Device-Checkboxes-Wrapper-Box'>
            <input
              checked={dependency.colorBlend}
              id='colorBlendCB'
              onChange={(e) =>
                setDependency({
                  ...dependency,
                  colorBlend: e.target.checked,
                })
              }
              type='checkbox'
            />
            <label htmlFor='colorBlendCB'>Color Blend</label>
          </div>
        </div>
        <div
          style={{
            margin: '1rem 0',
            width: '50%',
            cursor: !dependency.coverEnlargement ? 'not-allowed' : 'pointer',
          }}
        >
          <EditProperty description='Cover Enlargement Size:'>
            <input
              className='EditProperty-Input-Textfield'
              disabled={!dependency?.coverEnlargement}
              onChange={(event) => {
                setDependency({
                  ...dependency,
                  coverEnlargementSize: parseInt(event.target.value),
                })
              }}
              placeholder='Cover Enlargement Size'
              style={{
                cursor: !dependency.coverEnlargement ? 'not-allowed' : '',
              }}
              type='number'
              value={dependency?.coverEnlargementSize ? dependency.coverEnlargementSize : 0}
            />
          </EditProperty>
        </div>
      </div>
      <div>
        <EquipmentCombinations
          dependency={dependency}
          newEquipment={newEquipment}
          setDependency={setDependency}
          setNewEquipment={setNewEquipment}
        />
      </div>
    </>
  )
}

export default Dependencies
