import React, { FC } from 'react';

// Components

// Types
import { DeviceComponent } from 'types/Device';
import { Component } from 'types/Component';

// Styles
import './style.css';

interface Props {
  index: number;
  components: DeviceComponent[];
  component: DeviceComponent;
  componentNames: Component[];
  setComponents: (comps: DeviceComponent[]) => void;
  updateScene?: () => void;
}

const ComponentInput: FC<Props> = ({ index, component, setComponents, components, componentNames, updateScene }) => {
  const { id } = component.component;
  const { position, scaling } = component;

  const label = componentNames.find(cN => cN.id === id);

  const componentUpdate = (property: 'position' | 'scaling' | 'fixed' | 'backside', propertyValue: 'x' | 'y' | 'z' | null, value: number | boolean) => {
    const newComponent = components[index]; //components.find(c => c.component.id === id);
    if (propertyValue) {
      newComponent[property][propertyValue] = value;
    } else if ((property === 'fixed' || property === 'backside') && typeof value === 'boolean') {
      newComponent[property] = value;
    }
    const updatedComponents = [...components];
    updatedComponents.splice(index, 1, newComponent);
    setComponents(updatedComponents);
  };

  return (
    <div className="Component-Input">
      <h5>{label ? label.name : `Component ${id}`}</h5>
      <div className="Component-Input-Position">
        <span className="Component-Input-Element">Position</span>
        <div className="Component-Input-Element">
          <span>x:</span>
          <input
            className="Component-Input-Position-Textfield"
            onChange={event => componentUpdate('position', 'x', parseFloat(event.target.value))}
            onBlur={event => {
              if (typeof updateScene === 'function') updateScene();
            }}
            step={0.1}
            type="number"
            value={position.x}
          />
        </div>
        <div className="Component-Input-Element">
          <span>
            y:
            <input
              className="Component-Input-Position-Textfield"
              onChange={event => componentUpdate('position', 'y', parseFloat(event.target.value))}
              onBlur={event => {
                if (typeof updateScene === 'function') updateScene();
              }}
              step={0.1}
              type="number"
              value={position.y}
            />
          </span>
        </div>

        <div className="Component-Input-Element">
          <span>z:</span>
          <input
            className="Component-Input-Position-Textfield"
            onChange={event => componentUpdate('position', 'z', parseFloat(event.target.value))}
            onBlur={event => {
              if (typeof updateScene === 'function') updateScene();
            }}
            step={0.1}
            type="number"
            value={position.z}
          />
        </div>
      </div>
      <div className="Component-Input-Scaling">
        <div className="Component-Input-Element">
          <span>Scaling</span>
        </div>
        <div className="Component-Input-Element">
          <span>x:</span>
          <input
            className="Component-Input-Scaling-Textfield"
            onChange={event => componentUpdate('scaling', 'x', parseFloat(event.target.value))}
            onBlur={event => {
              if (typeof updateScene === 'function') updateScene();
            }}
            step={0.1}
            type="number"
            value={scaling.x}
          />
        </div>

        <div className="Component-Input-Element">
          <span>y:</span>
          <input
            className="Component-Input-Scaling-Textfield"
            onChange={event => componentUpdate('scaling', 'y', parseFloat(event.target.value))}
            onBlur={event => {
              if (typeof updateScene === 'function') updateScene();
            }}
            step={0.1}
            type="number"
            value={scaling.y}
          />
        </div>

        <div className="Component-Input-Element">
          <span>z:</span>
          <input
            className="Component-Input-Scaling-Textfield"
            onChange={event => componentUpdate('scaling', 'z', parseFloat(event.target.value))}
            onBlur={event => {
              if (typeof updateScene === 'function') updateScene();
            }}
            step={0.1}
            type="number"
            value={scaling.z}
          />
        </div>
      </div>
      <div>
        <input checked={component.fixed} id={`${index}-isFixedComponent`} onChange={e => componentUpdate('fixed', null, e.target.checked)} type="checkbox" />
        <label htmlFor={`${index}-isFixedComponent`}>Fixed</label>
      </div>
      <div>
        <input
          checked={component.backside}
          id={`${index}-isBacksideComponent`}
          onChange={e => componentUpdate('backside', null, e.target.checked)}
          type="checkbox"
        />
        <label htmlFor={`${index}-isBacksideComponent`}>Backside</label>
      </div>

      <button
        onClick={() => {
          const newComponents = [...components];
          newComponents.splice(index, 1);
          setComponents(newComponents);
        }}
      >
        Remove
      </button>
    </div>
  );
};

export default ComponentInput;
