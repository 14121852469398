import { Right } from 'types/Member';

export const isRightInputDisabled = (right: Right, rights: Right[]) => {
  if (right === 'ShowSinglePricesMasterline') {
    return !rights.includes('ShowTotalPriceMasterline');
  }
  if (right === 'ShowSinglePricesModular') {
    return !rights.includes('ShowTotalPriceModular');
  }
  if (right === 'ShowSinglePricesMarineMeister') {
    return !rights.includes('ShowTotalPriceMarineMeister');
  }
  return false;
};