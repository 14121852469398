export type HighPerformanceQueueType = boolean | Promise<boolean> | void | Promise<void>;

export default class HighPerformanceQueue {
  private static map = new Map<number, () => HighPerformanceQueueType>();
  private static queue = new Array<number>();
  private static isRunning = false;

  public static push(uniqueId: number, f: () => HighPerformanceQueueType) {
    if (HighPerformanceQueue.map.has(uniqueId)) {
      HighPerformanceQueue.map.set(uniqueId, f);
    } else {
      HighPerformanceQueue.map.set(uniqueId, f);
      HighPerformanceQueue.queue.push(uniqueId);
    }
    setTimeout(HighPerformanceQueue.call, 100);
  }

  private static call() {
    const id = HighPerformanceQueue.queue.shift();
    const f = HighPerformanceQueue.map.get(id);
    HighPerformanceQueue.map.delete(id);
    if (!HighPerformanceQueue.isRunning && f) {
      HighPerformanceQueue.isRunning = true;
      try {
        f();
      } catch (e) {
        console.error(e);
      }
      setTimeout(HighPerformanceQueue.call, 100);
      HighPerformanceQueue.isRunning = false;
    }
  }
}
