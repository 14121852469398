import posed from 'react-pose';

// ===================================================================
export const AMenu = posed.div({
  exit: {
    opacity: 0,
    transition: (props: any) => {
      return {
        duration: props.duration,
      };
    },
  },
  enter: {
    opacity: 1,
    transition: (props: any) => {
      return {
        duration: props.duration,
      };
    },
    beforeChildren: true,
    staggerChildren: 100,
  },
});

export const AItem = posed.div({
  exit: { opacity: 0, left: '-5%' },
  enter: { opacity: 1, left: 0, transition: { duration: 300 } },
});

// ===================================================================
export const ATableRow = posed.tr({
  exit: { opacity: 0, transition: { duration: 100 } },
  enter: { opacity: 1 },
});

// ===================================================================
export const ASlider = posed.div({
  left: {
    left: '2%',
    transition: { duration: 250 },
  },
  right: {
    left: '50%',
    transition: { duration: 250 },
  },
});

// ===================================================================
const EDITORMENU_DURATION = 125;

export const AEditorMenu = posed.div({
  exit: {
    opacity: 0,
    right: (props: any) => {
      return props.animationWidth ? props.animationWidth : 0;
    },
    transition: { duration: 2 * EDITORMENU_DURATION },
  },
  enter: {
    opacity: 1,
    right: 10,
    width: (props: any) => {
      return props.width ? props.width : 0;
    },
    transition: { duration: 3 * EDITORMENU_DURATION },
    beforeChildren: true,
  },
  expanded: {
    opacity: 1,
    right: 10,
    width: '100%',
    transition: { duration: 3 * EDITORMENU_DURATION },
  },
});

export const AEditorMenuItem = posed.div({
  exit: {
    opacity: 0,
    left: (props: any) => {
      return props.left ? props.left : '10%';
    },
  },
  enter: { opacity: 1, left: 0, transition: { duration: 100 }, delay: 125 },
});

// ===================================================================
const DURATION = 750;

export const AHeaderContent = posed.div({
  exit: { height: 0, opacity: 0, transition: { duration: DURATION } },
  enter: {
    height: (props: any) => (props.height ? props.height : 0),
    opacity: 1,
    transition: { duration: DURATION },
    beforeChildren: true,
    staggerChildren: 150,
  },
});

export const ASimple = posed.div({
  exit: {
    opacity: 0,
    transition: (props: any) => {
      const trans = props.duration
        ? { duration: props.duration }
        : { duration: 50 };
      return trans;
    },
  },
  enter: { opacity: 1, delay: (props: any) => (props.delay ? props.delay : 0) },
});

// ===================================================================
export const AShareDialog = posed.div({
  exit: {
    opacity: 0,
    top: (props: any) => (props.topExit ? props.topExit : 0),
    transition: { duration: 125 },
  },
  enter: {
    opacity: 1,
    top: (props: any) => (props.topEnter ? props.topEnter : 0),
    transition: { duration: 250 },
  },
});

// ===================================================================
export const ASimpleWithHeight = posed.div({
  exit: {
    opacity: 0,
    height: 0,
    transition: (props: any) => {
      const trans = props.duration
        ? { duration: props.duration }
        : { duration: 250 };
      return trans;
    },
  },
  enter: {
    opacity: 1,
    height: 'auto',
    delay: (props: any) => (props.delay ? props.delay : 0),
    transition: (props: any) => {
      const trans = props.duration
        ? { duration: props.duration }
        : { duration: 250 };
      return trans;
    },
    beforeChildren: true,
    staggerChildren: 150,
  },
});

// =====================================================================
export const ASearchbar = posed.div({
  inactive: {
    position: 'absolute',
    left: '-330px',
    transition: {
      duration: 250,
      ease: 'linear',
    },
  },
  activeSelect: {
    left: '280px',
    opacity: 1,
    transition: {
      duration: 250,
      ease: 'linear',
    },
  },
  active: {
    position: 'absolute',
    left: '0',
    transition: {
      duration: 150,
      ease: 'linear',
    },
  },
});

// =====================================================================
export const AConfigSearchbar = posed.div({
  inactive: {
    position: 'absolute',
    left: '0',
    transition: {
      duration: 200,
      ease: 'linear',
    },
  },
  active: {
    position: 'absolute',
    left: '-400px',
    transition: {
      duration: 120,
      ease: 'linear',
    },
  },
});
