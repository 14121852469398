import * as BABYLON from 'babylonjs';

export type Bounds = {
  x: MinMaxNumber;
  y: MinMaxNumber;
  z: MinMaxNumber;
  width: number;
  height: number;
  depth: number;
};

export type MinMaxNumber = {
  min: number;
  max: number;
};

export default class BasicUtils {
  public static generateRandomString(length: number): string {
    const range = '0123456789QWERTZUIOPLKJHGFDSAYXCVBNMqwertzuioplkjhgfdsayxcvbnm';
    let s = '';
    for (let i = 0; i < length; i++) {
      const random = Math.floor(Math.random() * range.length);
      s += range[random];
    }
    return s;
  }

  public static findFirstChild(name: string, container: BABYLON.TransformNode, directDescendantsOnly?: boolean): BABYLON.TransformNode {
    const children = container.getChildTransformNodes(directDescendantsOnly);
    // colon is used to chain names node1:node2 = node2 is a child of node1
    const index = name.indexOf(':');
    if (index > 0) {
      const firstName = name.substring(0, index);
      const lastName = name.substring(index + 1);
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.name === firstName) return this.findFirstChild(lastName, child, directDescendantsOnly);
      }
    } else {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.name === name) return child;
      }
    }

    return null;
  }

  public static findChildren(name: string, container: BABYLON.TransformNode, directDescendantsOnly?: boolean): Array<BABYLON.TransformNode> {
    const arr: Array<BABYLON.TransformNode> = [];
    const children = container.getChildTransformNodes(directDescendantsOnly);
    // colon is used to chain names node1:node2 = node2 is a child of node1
    const index = name.indexOf(':');
    if (index > 0) {
      const firstName = name.substring(0, index);
      const lastName = name.substring(index + 1);
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.name === firstName) arr.push(...this.findChildren(lastName, child, directDescendantsOnly));
      }
    } else {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.name === name) arr.push(child);
      }
    }

    return arr;
  }

  public static getBounds(node: BABYLON.Node): Bounds {
    const bounds = {
      x: {
        min: 0,
        max: 0
      },
      y: {
        min: 0,
        max: 0
      },
      z: {
        min: 0,
        max: 0
      },
      width: 0,
      height: 0,
      depth: 0
    };

    let firstChildIsMesh = false;
    if (node instanceof BABYLON.AbstractMesh) {
      const min = node.getBoundingInfo().minimum;
      const max = node.getBoundingInfo().maximum;

      bounds.x.min = min.x;
      bounds.y.min = min.y;
      bounds.z.min = min.z;
      bounds.x.max = max.x;
      bounds.y.max = max.y;
      bounds.z.max = max.z;

      firstChildIsMesh = true;
    }

    const children = node.getChildMeshes();
    for (let i = 0; i < children.length; i++) {
      const m = children[i];
      if (!m.isEnabled()) continue;
      const min = m.getBoundingInfo().minimum;
      const max = m.getBoundingInfo().maximum;

      if (i < 1 && !firstChildIsMesh) {
        bounds.x.min = min.x;
        bounds.y.min = min.y;
        bounds.z.min = min.z;
        bounds.x.max = max.x;
        bounds.y.max = max.y;
        bounds.z.max = max.z;
      } else {
        if (bounds.x.min > min.x) bounds.x.min = min.x;
        if (bounds.y.min > min.y) bounds.y.min = min.y;
        if (bounds.z.min > min.z) bounds.z.min = min.z;
        if (bounds.x.max < max.x) bounds.x.max = max.x;
        if (bounds.y.max < max.y) bounds.y.max = max.y;
        if (bounds.z.max < max.z) bounds.z.max = max.z;
      }
    }

    bounds.width = Math.abs(bounds.x.max - bounds.x.min);
    bounds.height = Math.abs(bounds.y.max - bounds.y.min);
    bounds.depth = Math.abs(bounds.z.max - bounds.z.min);

    return bounds;
  }

  public static copy(root: BABYLON.TransformNode, name: string, parent?: BABYLON.TransformNode): BABYLON.TransformNode {
    let copy = null;
    if (root instanceof BABYLON.Mesh) {
      copy = root.createInstance(name);
      copy.parent = parent;
    } else {
      copy = root.clone(null, parent, true);
      copy.name = name;
    }

    const children = root.getChildTransformNodes(true);
    for (let i = 0; i < children.length; i++) {
      const element = children[i];
      this.copy(element, element.name, copy);
    }
    return copy;
  }

  public static clone(root: BABYLON.TransformNode, name?: string, parent?: BABYLON.TransformNode): BABYLON.TransformNode {
    const clone = root.clone(root.name, parent || root.parent, true);
    clone.name = name || root.name;
    clone.id = root.id;
    const children = root.getChildTransformNodes(true);
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      this.clone(child, undefined, clone);
    }
    return clone;
  }

  public static color(r: number, g: number, b: number) {
    return new BABYLON.Color3(r / 255, g / 255, b / 255);
  }

  /**
   * Checks if a given Point is inside a Poly
   *
   * @param poly Poly of Vector2 elements
   * @param point Vector2 to check
   * @return true if the Point is inside the Poly
   */
  public static isPointInPoly(
    poly: Array<{
      x: number;
      y: number;
    }>,
    point: {
      x: number;
      y: number;
    }
  ): boolean {
    if (poly.length < 3) return false;
    let i = 0,
      j = 0,
      l = poly.length;
    let c = false;
    for (i = 0, j = l - 1; i < l; j = i++) {
      if (poly[i].y > point.y != poly[j].y > point.y && point.x < ((poly[j].x - poly[i].x) * (point.y - poly[i].y)) / (poly[j].y - poly[i].y) + poly[i].x)
        c = !c;
    }
    return c;
  }

  public static computeAllWorldMatrix(node: BABYLON.TransformNode) {
    const childs = node.getChildTransformNodes();
    node.computeWorldMatrix(true);
    for (let i = 0; i < childs.length; i++) {
      const child = childs[i];
      child.computeWorldMatrix(true);
    }
  }
}
