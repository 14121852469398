import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

// Components:
import {
  Button,
  ButtonHollow,
  EditProperty,
  LoadingSpinner,
  UploadObject,
} from 'components';

// Styles:
import './style.css';
import 'react-table/react-table.css';

// Utils:
import { get, post } from 'utils/AJAX';
import {
  SceneEventArgs,
  SceneMountReturn,
} from 'components/babylon/BabylonScene';

// Babylon:
import * as BABYLON from 'babylonjs';
import PreviewBabylonScene from 'components/babylon/PreviewBabylonScene';
import LoaderUtils, { LoadReturn } from 'components/babylon/util/LoaderUtils';

// =========================================================
interface Props extends RouteComponentProps {}
// =========================================================

const ComponentEdit: FC<Props> = ({ history }) => {
  const id = window.location.pathname.split('/')[2];
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [langNotFound, setLangNotFound] = useState(false);
  const [saveSuccessfull, setSaveSuccessfull] = useState(false);
  const [saving, setSaving] = useState(false);

  // =================================

  const saveChanges = async () => {
    const newData = id === 'new' ? { name } : { id, name };
    setSaving(true);
    const { data, error } = await post(
      `${process.env.REACT_APP_API_URL}/component/save`,
      {
        data: newData,
      }
    );
    if (data) {
      setSaveSuccessfull(true);
      if (id === 'new') {
        history.push(`/components/${data.id}`);
      }
    }
    if (error) {
      console.log(error);
    }
    setSaving(false);
  };

  // =================================

  useEffect(() => {
    const fetchComponent = async () => {
      setLoading(true);

      const { data, error } = await get(
        `${process.env.REACT_APP_API_URL}/component/get/${id}`
      );

      if (data) {
        setName(data.name);
        if (langNotFound) {
          setLangNotFound(false);
        }
      }
      if (error) {
        setLangNotFound(true);
      }
      setLoading(false);
    };
    if (id && id !== 'new') {
      fetchComponent();
    }
  }, [id, langNotFound]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (langNotFound) {
    return <div>Component not found.</div>;
  }

  const onSceneMounted = (e: SceneEventArgs) => {
    const sceneMountReturn: SceneMountReturn = {
      resizeEvents: new Array<() => void>(),
    };
    // const { canvas, scene, engine } = e;

    refreshPreview();

    return sceneMountReturn;
  };

  // let previewObject: DeviceNode = null;
  // const refreshPreview = () => {
  //   if (previewObject && !previewObject.isDisposed) {
  //     previewObject.dispose();
  //   }
  //   LoaderUtils.genCorpus({
  //     left: true,
  //     right: true,
  //     width: 600,
  //     depth: 850
  //   }, undefined, (node: DeviceNode) => {
  //     previewObject = node;
  //     LoaderUtils.load(`${process.env.REACT_APP_API_URL}/component/get/${id}/object`, undefined, (lr: LoadReturn) => {
  //       previewObject.addOption(lr.model);
  //     });
  //   });
  // }

  let previewObject: BABYLON.TransformNode = null;
  const refreshPreview = () => {
    if (previewObject && !previewObject.isDisposed()) {
      previewObject.dispose();
    }
    LoaderUtils.load(
      `${
        process.env.REACT_APP_API_URL
      }/component/get/${id}/object?_=${Date.now()}`,
      (lr: LoadReturn) => {
        previewObject = lr.model;
        previewObject.parent = undefined;
      }
    );
  };

  return (
    <div className="Component-Wrapper">
      <div>
        <h1>{id === 'new' ? 'Create Component' : 'Edit Component'}</h1>
      </div>
      <EditProperty description="Name:">
        <input
          className="EditProperty-Input-Textfield"
          onChange={(event) => {
            setName(event.target.value);
          }}
          placeholder="Name"
          value={name}
        />
      </EditProperty>

      <div>
        <UploadObject
          id={id}
          parentType="component"
          onUploadSuccess={(data: any) => {
            refreshPreview();
          }}
        />
      </div>

      <div className="Component-Wrapper-Preview">
        <PreviewBabylonScene onSceneMounted={onSceneMounted} />
      </div>

      <div className="Component-Wrapper-ButtonContainer">
        <ButtonHollow onClick={() => history.push('/components')}>
          Cancel
        </ButtonHollow>

        <Button btnType="first" onClick={saveChanges}>
          Save
        </Button>
      </div>
      {saveSuccessfull && <div>Saved Changes.</div>}
      {saving && <LoadingSpinner />}
    </div>
  );
};

export default ComponentEdit;
