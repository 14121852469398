import * as BABYLON from 'babylonjs';
import LineUtils from './LineUtils';

export enum Orientation {
  Top,
  Bottom,
  Left,
  Right
}

export type DrawLabelOptions = {
  depth: number;
  lineMaterial: BABYLON.StandardMaterial;
};

export default class LabelUtils {
  private static scene: BABYLON.Scene;

  private static labelTextureScale = 0.5;

  private static offsetY = 0.2;

  private static materialCache = new Map<string, BABYLON.Material>();
  private static textureCache = new Map<string, BABYLON.DynamicTexture>();

  public static labelWidth = 40;
  public static lineMaterial: BABYLON.StandardMaterial;
  public static lineMaterialBlue: BABYLON.StandardMaterial;

  public static initialize(scene: BABYLON.Scene): void {
    LabelUtils.scene = scene;

    LabelUtils.materialCache.clear();
    LabelUtils.textureCache.clear();

    LabelUtils.lineMaterial = new BABYLON.StandardMaterial('label:line', scene);
    LabelUtils.lineMaterial.diffuseColor = BABYLON.Color3.Black();
    LabelUtils.lineMaterial.specularColor = BABYLON.Color3.Black();
    LabelUtils.lineMaterial.emissiveColor = new BABYLON.Color3(0.4, 0.4, 0.4);
    LabelUtils.lineMaterial.disableLighting = true;
    // LabelUtils.lineMaterial.wireframe = true;
    LabelUtils.lineMaterial.freeze();

    LabelUtils.lineMaterialBlue = new BABYLON.StandardMaterial('label:line:blue', scene);
    LabelUtils.lineMaterialBlue.diffuseColor = BABYLON.Color3.Black();
    LabelUtils.lineMaterialBlue.specularColor = BABYLON.Color3.Black();
    LabelUtils.lineMaterialBlue.emissiveColor = new BABYLON.Color3(37 / 255, 177 / 255, 255 / 255);
    LabelUtils.lineMaterialBlue.disableLighting = true;
    LabelUtils.lineMaterialBlue.freeze();
  }

  public static drawText(label: string): BABYLON.DynamicTexture {
    let texture = this.textureCache.get(label);
    if (!texture) {
      texture = new BABYLON.DynamicTexture(
        'label:text:' + label,
        256 * LabelUtils.labelTextureScale,
        LabelUtils.scene,
        false
      );
      const textY =
        (256 * LabelUtils.labelTextureScale) / 2 +
        (65 * LabelUtils.labelTextureScale) / 2 -
        65 * LabelUtils.labelTextureScale * 0.15;
      texture.drawText(
        label,
        null,
        textY,
        'bold ' + (65 * LabelUtils.labelTextureScale).toFixed(0) + 'px Segoe UI, sans-serif',
        'black',
        'transparent',
        true
      );
    }
    return texture;
  }

  public static drawLabel(
    label: string | BABYLON.Material,
    size: number,
    orientation: Orientation,
    options: DrawLabelOptions = {
      depth: 40,
      lineMaterial: LabelUtils.lineMaterial
    }
  ): BABYLON.TransformNode {
    const container = new BABYLON.TransformNode('label', LabelUtils.scene);

    const textPlane = BABYLON.Mesh.CreateGround(
      'text',
      LabelUtils.labelWidth,
      LabelUtils.labelWidth,
      1
    );

    if (label instanceof BABYLON.Material) {
      textPlane.material = label;
    } else {
      if (LabelUtils.materialCache.has(label)) {
        textPlane.material = LabelUtils.materialCache.get(label);
      } else {
        const dynamicTexture = LabelUtils.drawText(label);

        const material = new BABYLON.StandardMaterial('label:text:' + label, LabelUtils.scene);
        material.opacityTexture = dynamicTexture;
        // material.diffuseTexture = dynamicTexture;
        // material.diffuseTexture.hasAlpha = true;
        material.diffuseColor = BABYLON.Color3.Black();
        material.specularColor = BABYLON.Color3.Black();
        material.emissiveColor = options.lineMaterial.emissiveColor;
        material.disableLighting = true;
        material.freeze();
        LabelUtils.materialCache.set(label, material);

        textPlane.material = material;
      }
    }
    textPlane.isPickable = false;

    switch (orientation) {
      case Orientation.Top:
      case Orientation.Bottom:
        {
          const invertZ = orientation === Orientation.Bottom;
          let w = (size - LabelUtils.labelWidth / 2) / 2;
          const depthMultiplier = invertZ ? -1 : 1;
          const lineDepth = options.depth * depthMultiplier;

          if (w <= 0) w = 0;

          textPlane.position = new BABYLON.Vector3(size / 2, LabelUtils.offsetY, lineDepth);
          textPlane.parent = container;

          if (label instanceof BABYLON.Material) {
            // ???
          } else {
            if (label.length > 3) {
              const overflow = label.length - 3;
              w -= overflow * 3;
            }
          }

          if (w <= 0) w = 1;

          const meshes = [];

          const leftLine = LineUtils.drawLine(
            null,
            {
              path: [
                new BABYLON.Vector3(0, 0, 0),
                new BABYLON.Vector3(0, 0, lineDepth),
                new BABYLON.Vector3(w, 0, lineDepth)
              ],
              width: 1,
              material: options.lineMaterial
            },
            LabelUtils.scene
          );
          leftLine.position = new BABYLON.Vector3(0, LabelUtils.offsetY, 0);
          leftLine.parent = container;
          meshes.push(leftLine);

          const rightLine = LineUtils.drawLine(
            null,
            {
              path: [
                new BABYLON.Vector3(size - w, 0, lineDepth),
                new BABYLON.Vector3(size, 0, lineDepth),
                new BABYLON.Vector3(size, 0, 0)
              ],
              width: 1,
              material: options.lineMaterial
            },
            LabelUtils.scene
          );
          rightLine.position = new BABYLON.Vector3(0, LabelUtils.offsetY, 0);
          rightLine.parent = container;
          meshes.push(rightLine);

          const finalMesh = BABYLON.Mesh.MergeMeshes(meshes);
          finalMesh.name = 'line';
          finalMesh.parent = container;
        }
        break;
      case Orientation.Left:
      case Orientation.Right:
        {
          const invertX = orientation === Orientation.Left;
          let w = (size - LabelUtils.labelWidth / 2) / 2;
          const widthMultiplier = invertX ? -1 : 1;
          const lineWidth = options.depth * widthMultiplier;

          if (w <= 0) w = 0;

          textPlane.position = new BABYLON.Vector3(lineWidth, LabelUtils.offsetY, size / 2);
          textPlane.parent = container;

          if (w <= 0) w = 1;

          const meshes = [];

          const leftLine = LineUtils.drawLine(
            null,
            {
              path: [
                new BABYLON.Vector3(0, 0, 0),
                new BABYLON.Vector3(lineWidth, 0, 0),
                new BABYLON.Vector3(lineWidth, 0, w)
              ],
              width: 1,
              material: options.lineMaterial
            },
            LabelUtils.scene
          );
          leftLine.position = new BABYLON.Vector3(0, LabelUtils.offsetY, 0);
          leftLine.parent = container;
          meshes.push(leftLine);

          const rightLine = LineUtils.drawLine(
            null,
            {
              path: [
                new BABYLON.Vector3(lineWidth, 0, size - w),
                new BABYLON.Vector3(lineWidth, 0, size),
                new BABYLON.Vector3(0, 0, size)
              ],
              width: 1,
              material: options.lineMaterial
            },
            LabelUtils.scene
          );
          rightLine.position = new BABYLON.Vector3(0, LabelUtils.offsetY, 0);
          rightLine.parent = container;
          meshes.push(rightLine);

          const finalMesh = BABYLON.Mesh.MergeMeshes(meshes);
          finalMesh.name = 'line';
          finalMesh.parent = container;
          finalMesh.isPickable = false;
        }
        break;

      default:
        break;
    }

    return container;
  }
}
