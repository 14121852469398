import React, { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';

// Components:
import { ButtonHollow } from 'components';

// Utils:
import { post } from 'utils/AJAX';
import { DxfCoordinates } from '../../types/DxfCoordinates';

interface Props {
  coordinates: DxfCoordinates;
}

export const UploadDXF: FC<Props> = ({ coordinates }) => {
  const id = window.location.pathname.split('/')[2];

  const [error, setError] = useState(false);
  const [uGutter, setUGutter] = useState(false);
  const [removeEdges, setRemoveEdges] = useState(false);
  const [success, setSuccess] = useState(false);

  const uploadObject = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const url = `device/save/${id}/model/dxf?depth=${coordinates.depth}&x=${coordinates.x}&y=${coordinates.y}&mbr=${removeEdges}&ugutter=${uGutter}`;

    const { data, error } = await post(
      `${process.env.REACT_APP_API_URL}/${url}`,
      {
        data: formData,
      }
    );

    if (data) {
      setSuccess(true);
      setError(false);
    } else if (error) {
      setError(true);
      setSuccess(false);
    }
  };

  const onDrop = (files: File[]) => {
    uploadObject(files[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div>
      <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
        <input
          checked={removeEdges}
          id="remove-edges-box"
          onChange={() => setRemoveEdges(!removeEdges)}
          type="checkbox"
        />
        <label htmlFor="remove-edges-box">Remove edges</label>
      </div>
      <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
        <input
          checked={uGutter}
          id="ugutter-box"
          onChange={() => setUGutter(!uGutter)}
          type="checkbox"
        />
        <label htmlFor="ugutter-box">Add U-Gutter automatically</label>
      </div>
      {success && <p>DXF Changed.</p>}
      {error && <p>Could not change DXF.</p>}

      <div style={{ marginTop: '1rem' }}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <ButtonHollow onClick={() => {}}>Upload DXF</ButtonHollow>
        </div>
      </div>
    </div>
  );
};
