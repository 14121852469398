import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

// Components:
import { Button, ButtonHollow, EditProperty, LoadingSpinner } from 'components';

// Styles:
import './style.css';
import 'react-table/react-table.css';

// Utils:
import { get, post } from 'utils/AJAX';

// =========================================================
interface Props extends RouteComponentProps {}
// =========================================================

const LanguageEdit: FC<Props> = ({ history }) => {
  const id = window.location.pathname.split('/')[2];
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [langNotFound, setLangNotFound] = useState(false);
  const [saveSuccessfull, setSaveSuccessfull] = useState(false);
  const [saving, setSaving] = useState(false);

  // =================================

  const saveChanges = async () => {
    const newData =
      id === 'new'
        ? { name, code, default: isDefault }
        : { id, name, code, default: isDefault };
    setSaving(true);
    const { data, error } = await post(
      `${process.env.REACT_APP_API_URL}/language/save`,
      {
        data: newData,
      }
    );
    if (data) {
      setSaveSuccessfull(true);
    }
    if (error) {
      console.log(error);
    }
    setSaving(false);
  };

  // =================================

  useEffect(() => {
    const fetchLanguage = async () => {
      setLoading(true);

      const { data, error } = await get(
        `${process.env.REACT_APP_API_URL}/language/get/${id}`
      );

      if (data) {
        setCode(data.code);
        setName(data.name);
        setIsDefault(data.default);
        if (langNotFound) {
          setLangNotFound(false);
        }
      }
      if (error) {
        setLangNotFound(true);
      }
      setLoading(false);
    };
    if (id && id !== 'new') {
      fetchLanguage();
    }
  }, [id, langNotFound]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (langNotFound) {
    return <div>Language not found.</div>;
  }

  return (
    <div className="Language-Wrapper">
      <div>
        <h1>{id === 'new' ? 'Create Language' : 'Edit Language'}</h1>
      </div>
      <EditProperty description="Name:">
        <input
          className="EditProperty-Input-Textfield"
          onChange={(event) => {
            setName(event.target.value);
          }}
          placeholder="Name"
          value={name}
        />
      </EditProperty>
      <EditProperty description="Code:">
        <input
          className="EditProperty-Input-Textfield"
          onChange={(event) => {
            setCode(event.target.value);
          }}
          placeholder="Code"
          value={code}
        />
      </EditProperty>
      <EditProperty>
        <input
          checked={isDefault}
          id="defaultCheck"
          onChange={(e) => setIsDefault(e.target.checked)}
          type="checkbox"
        />
        <label htmlFor="defaultCheck">Default</label>
      </EditProperty>

      <div className="Language-Wrapper-ButtonContainer">
        <ButtonHollow onClick={() => history.push('/languages')}>
          Cancel
        </ButtonHollow>

        <Button btnType="first" onClick={saveChanges}>
          Save
        </Button>
      </div>
      {saveSuccessfull && <div>Saved Changes.</div>}
      {saving && <LoadingSpinner />}
    </div>
  );
};

export default LanguageEdit;
