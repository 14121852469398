import React, { FC } from 'react';

// Components
import { EditProperty } from 'components';

// Types:
import { Translation } from 'types/Device';
import Language from 'types/Language';

interface Props {
  languages: Language[];
  setTranslations: (t: Translation) => void;
  translations: Translation;
}

const Translations: FC<Props> = ({
  languages,
  translations,
  setTranslations,
}) => {
  return (
    <>
      <h3 className="Device-SubCategory-Header">Translations</h3>

      <div className="Device-Languages">
        {languages.map((l) => {
          const language = l.id;
          const translationData = translations[language];
          return (
            <div key={language}>
              <p className="Device-Languages-Language">{l.name}</p>

              <div className="Device-SubCategory">
                <EditProperty description="Name:">
                  <input
                    className="EditProperty-Input-Textfield"
                    onChange={(event) => {
                      const newTranslations = { ...translations };
                      newTranslations[language] = {
                        ...newTranslations[language],
                        name: event.target.value,
                      };
                      setTranslations(newTranslations);
                    }}
                    placeholder="Enter Name"
                    value={translationData ? translationData.name : ''}
                  />
                </EditProperty>
                <EditProperty description="Description:">
                  <textarea
                    className="EditProperty-Input-Textarea"
                    onChange={(event) => {
                      const newTranslations = { ...translations };
                      newTranslations[language] = {
                        ...newTranslations[language],
                        description: event.target.value,
                      };
                      setTranslations(newTranslations);
                    }}
                    placeholder="Enter description"
                    value={translationData ? translationData.description : ''}
                  />
                </EditProperty>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Translations;
