import { useState } from 'react'
import { post } from 'utils/AJAX'

export const useSyncAllDevices = () => {
  const [syncing, setSyncing] = useState(false)
  const [showSyncAllDialog, setShowSyncAllDialog] = useState(false)
  const [syncError, setSyncError] = useState(false)
  const syncAllDevices = async () => {
    setSyncing(true)
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/sync/all`, {})
    if (data) {
      setSyncing(false)
      setShowSyncAllDialog(false)
    } else if (error) {
      setSyncError(true)
    }
    setSyncing(false)
  }
  return {
    syncAllDevices,
    syncing,
    syncError,
    showSyncAllDialog,
    setShowSyncAllDialog,
  }
}
