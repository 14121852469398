import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';

// Components:

// Context:
import AuthContext from 'context/auth-context';

// Style:
import './style.css';

// Utils:
import { post } from 'utils/AJAX';

const Login = () => {
  const { handleLog, setUserRights } = useContext(AuthContext);
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState('');

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/admin/auth/login`, {
      data: { username: user, password }
    });
    if (data) {
      if (data.key) {
        window.localStorage.setItem('jwt-mkn-admin', data.key);
      }
      if (data.rights) {
        window.localStorage.setItem('mkn-userRights', JSON.stringify(data.rights));
        setUserRights(data.rights);
      }
      setTimeout(() => {
        handleLog(data.loggedIn);
      }, 300);
    } else if (error) {
      setLoginError(true);
    }
    setLoading(false);
  };

  return (
    <div className="LoginPage">
      <div className="LoginPage-InputArea">
        <h1>Login</h1>

        <form className="LoginPage-Form" onSubmit={e => handleSubmit(e)}>
          <div>
            {loginError && <p className="LoginPage-ErrorMessage">Something went wrong</p>}
            <input
              autoComplete="email"
              className="LoginPage-InputField"
              onChange={event => setUser(event.target.value)}
              placeholder="NAME"
              type="text"
              value={user}
            />
            <input
              autoComplete="current-password"
              className="LoginPage-InputField"
              onChange={event => setPassword(event.target.value)}
              placeholder="PASSWORD"
              type="password"
              value={password}
            />
          </div>
          <button className="LoginPage-Button" disabled={loading} onClick={handleSubmit}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default withRouter(Login);
