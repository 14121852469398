import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import ReactTable from 'react-table'

// Components:
import { Button, LoadingSpinner } from 'components'

// Styles:
import 'react-table/react-table.css'
import './style.scss'

// Utils:
import { post } from 'utils/AJAX'
import { Organization } from 'types/Organization'

// =====================================================

interface Props extends RouteComponentProps {}

// =====================================================

const Organizations: FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(false)
  const ROWS = 11
  const [offset, setOffset] = useState(0)
  const [query, setQuery] = useState('')
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const [results, setResults] = useState(ROWS)

  // =================================

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    /* {
      id: 'admin',
      Header: 'Admin',
      accessor: (u: any) => {
        if (u.rights) {
          return u.rights.map((r: any, index: number) =>
            index < u.rights.length - 1 ? `${r}, ` : `${r}`
          )
        }
      },
    }, */
  ]

  const fetchOrganizations = async () => {
    setLoading(true)

    const { data, error, count } = await post(
      `${process.env.REACT_APP_API_URL}/organization/search`,
      {
        data: {
          rows: ROWS,
          offset,
          query: query,
          sort: {
            field: 'name',
            order: 'ASC',
          },
        },
      }
    )
    if (count) {
      setResults(count)
    }
    if (data) {
      setOrganizations(data)
    }
    if (error) {
      console.log(error)
    }
    setLoading(false)
  }

  // =================================

  useEffect(() => {
    fetchOrganizations()
  }, [offset])

  useEffect(() => {
    setOffset(0)
  }, [query])

  return (
    <div className='Organizations'>
      <div>
        <h1>Organizations</h1>
      </div>
      {loading && <LoadingSpinner />}

      <div className='Organizations-Options'>
        <div className='Organizations-Options-Searchform'>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              fetchOrganizations()
            }}
          >
            <input
              className='Organizations-Options-Searchform-Textfield'
              onChange={(event) => {
                setQuery(event.target.value)
              }}
              placeholder='Filter Organizations'
              type='search'
              value={query}
            />
            {/* <Button btnType="second" type="submit">
              Apply
            </Button> */}
          </form>
        </div>

        <Button btnType='first' onClick={() => history.push('/organizations/new')}>
          Create Organization
        </Button>
      </div>
      <div className='Organizations-Content'>
        <ReactTable
          columns={columns}
          data={organizations}
          resolveData={(data) => {
            const formatted = []
            for (let index = 0; index < offset; index++) {
              if (index < offset) {
                formatted.push({ translations: { vdrqR: { name: '' } } })
              }
            }
            formatted.push(...data)
            if (query === '') {
              for (let index = formatted.length; index < results; index++) {
                formatted.push({ translations: { vdrqR: { name: '' } } })
              }
            }
            return formatted
          }}
          defaultPageSize={10}
          showPageSizeOptions={false}
          getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
            return {
              onClick: (e: any, handleOriginal: any) => {
                if (rowInfo) {
                  history.push(`/organizations/${rowInfo.original.id}`)
                }
              },
            }
          }}
          onPageChange={(page) => {
            if (offset <= results) {
              setOffset(page * 10)
            }
          }}
        />
      </div>
    </div>
  )
}

export default Organizations
